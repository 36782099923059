import { Box, HStack, Image, SimpleGrid, VStack } from "@chakra-ui/react";
import { StaffRoutes } from "appRoutePaths";
import { useIdentity } from "context/auth";
import useProfile from "hooks/useProfile";
import { FC, Suspense } from "react";
import AdminDashboardCard from "./AdminDashboardCard";
import AdminDashboardData from "./AdminDashboardData";
import { Button, Skeleton } from "antd";
import { PaperClipOutlined } from "@ant-design/icons";

const backgroundImage = require("images/staff_dashboard.jpg");

const SkeletonCard = () => <VStack
    bg="white"
    rounded={3}
    p={3}
    align="left"
    spacing={2}>
    <Box>
        <Box pb={1}><Skeleton.Input active /></Box>
        <Box pt={1}><Skeleton.Input active size="small" block /></Box>
    </Box>
    <Box>
        <Skeleton.Input active block />
    </Box>
    <Box w="100%" h={1} bg="lightGreen" />
    <Skeleton.Input active size="small" />
</VStack>;

const SkeletonGrid = () => <SimpleGrid columns={2} spacing={6} pb={6}>
    <SkeletonCard /><SkeletonCard /><SkeletonCard />
    <SkeletonCard /><SkeletonCard /><SkeletonCard />
</SimpleGrid>;

const AdminDashboard: FC = () => {
    const { data: profile } = useProfile();
    const { isAdmin } = useIdentity();
    const name = profile?.firstName ?? "Admin";

    return (
        <VStack
            align="start"
            h="100%"
            w="100%"
            bg="transparent"
            pos="relative"
            fontFamily="body"
        >
            <Image
                pos="absolute"
                zIndex={-1}
                src={backgroundImage}
                alt="DC Sports 87"
                objectFit="cover"
                objectPosition="top"
                w="100%"
                h="100%"
            />
            <Box p={6}>
                <HStack display='flex' justifyContent='space-between'>
                    <Box color="white" fontSize={42}>
                        Hi, {name}
                    </Box>
                    {isAdmin && <Button
                        size="large"
                        icon={<PaperClipOutlined />}
                        href={StaffRoutes.auditLog.url}>
                        Audit Log
                    </Button>}
                </HStack>
                <Suspense fallback={<SkeletonGrid />}>
                    <AdminDashboardData isAdmin={isAdmin!!} />
                </Suspense>
                {isAdmin && <AdminDashboardCard
                    title="Need to update current wait times or push notifications globally?"
                    linkText="Go to Website Updates Page"
                    linkTo={StaffRoutes.websiteUpdates.url}
                />}
            </Box>
        </VStack>
    );
};

export default AdminDashboard;
