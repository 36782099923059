import {
    Box,
    Button,
    HStack,
    ModalBody,
    ModalFooter,
    VStack,
} from "@chakra-ui/react";
import { Form } from "antd";
import { FormInstance } from "antd/lib/form/Form";
import CustomerSelector from "components/CustomerSelector";
import { useIdentity } from "context/auth";
import { usePendingFilteredPayouts } from "hooks/usePayoutRequests";
import useProfile from "hooks/useProfile";
import { ApplicationUser } from "models/applicationUsers";
import {
    CreatePayoutRequestPayload,
    ValidPaymentMethods,
} from "models/payoutRequests";
import { FC, Suspense, useEffect, useState } from "react";
import { dollarsToCents } from "util/helpers";
import AvailableBalance from "../AvailableBalance";
import PayoutMethodDetailsForms from "../PayoutMethodDetailForms";
import AmountFormItem from "../PayoutMethodDetailForms/Inputs/AmountFormItem";
import FormInput from "../PayoutMethodDetailForms/Inputs/FormInput";
import PayoutMethodSelector from "../PayoutMethodSelector";

const PayoutDetailsStep1: FC<{
    form: FormInstance<any>;
    active: boolean;
    onClose: () => void;
    onNextStep: (payoutRequest: CreatePayoutRequestPayload) => void;
}> = ({ form, active, onClose, onNextStep }) => {
    const [method, setMethod] = useState<ValidPaymentMethods | undefined>();
    const { data: profile } = useProfile();

    useEffect(() => {
        // setting form defaults based on profile data whenever
        // the payout method changes
        if (profile) {
            const fullName = `${profile?.firstName} ${profile?.lastName}`;

            form.setFieldsValue({
                name: fullName,
                payee: fullName,
                nameOnAccount: fullName,
                ...profile
            });
        }
    }, [form, method, profile]);

    const onFinish = async (values: any) => {
        // TODO: When the user tries to hit next without selecting a method, show an error message
        if (!method) {
            return;
        }
        const { amount, customer, ...rest } = values;

        const customerObj = customer as ApplicationUser | undefined;

        const payoutRequest: CreatePayoutRequestPayload = {
            method,
            amountInCents: dollarsToCents(amount),
            customerId: customerObj?.id,
            details: {
                // Everything else in the form is the dynamic part of the details
                ...rest,
            },
        };

        onNextStep(payoutRequest);
    };

    const { isAdmin } = useIdentity();

    // Find out if the user already has the selected method in a pending request
    // If this is an admin, then don't check
    const { data: pendingPayouts } = usePendingFilteredPayouts();
    const selectedMethodExists =
        !isAdmin &&
        method &&
        pendingPayouts?.data.some((payout) => payout.method === method);
    const submitDisabled = selectedMethodExists;

    return active ? (
        <Box as={Form} form={form} layout="vertical" onFinish={onFinish}>
            <ModalBody>
                <VStack
                    spacing={2}
                    w="100%"
                    align="start"
                    maxW={{ base: "100%", md: "40rem" }}
                >
                    {isAdmin ? (
                        <FormInput
                            label="Customer"
                            name="customer"
                            minW="15rem"
                        >
                            <CustomerSelector
                                onSelected={(customer) => {
                                    form.setFieldsValue({ customer });
                                }}
                                onClear={() => {
                                    form.setFieldsValue({ customer: null });
                                }}
                                initialEntity={form.getFieldValue("customer")}
                            />
                        </FormInput>
                    ) : (
                        <Box fontSize={26} width="13.5em">
                            <Suspense>
                                <AvailableBalance />
                            </Suspense>
                        </Box>
                    )}

                    <HStack spacing={2} w="100%" align="start">
                        <PayoutMethodSelector
                            value={method}
                            onSelect={setMethod}
                            selectedMethodExists={selectedMethodExists}
                        />
                        <AmountFormItem />
                    </HStack>
                    <PayoutMethodDetailsForms method={method} />
                </VStack>
            </ModalBody>
            <ModalFooter as={HStack}>
                <Button variant="danger" onClick={onClose} size="sm">
                    Cancel
                </Button>
                <Button
                    variant="primary"
                    size="sm"
                    type="submit"
                    disabled={submitDisabled}
                >
                    Review Request
                </Button>
            </ModalFooter>
        </Box>
    ) : null;
};

export default PayoutDetailsStep1;
