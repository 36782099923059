import { Box, Flex, HStack, Image, VStack } from "@chakra-ui/react";
import { ContactDetailsForm } from "components/customer/ContactDetails/ContactDetailsForm";
import useProfile from "hooks/useProfile";
import { FC } from "react";
import { Route, Routes } from "react-router-dom";

const ContactDetailsImage = require("images/contact-details-page.png");

const NewUser: FC = () => {
    return (
        <Flex
            w="100%"
            bg="var(--dcs-light-green)"
            py={2}
            justifyContent="space-around"
            fontSize={16}
        >
            Account created!
        </Flex>
    );
};

const ContactDetailsSection: FC = () => {
    // This also kicks the user out to login if they need to be logged in for this page
    const { data } = useProfile();
    const firstName = data?.firstName ?? "User";

    return (
        <VStack maxWidth="45rem" align="flex-start">
            <VStack align="baseline" flex={1}>
                <Box fontSize={36} fontWeight="medium">
                    Welcome, {firstName}!
                </Box>
                <Box fontSize={22}>
                    Before getting to your dashboard, let’s confirm a few more
                    details to make selling easy for you!
                </Box>
            </VStack>
            <ContactDetailsForm />
        </VStack>
    );
};

const AccentImage: FC = () => (
    <Image
        src={ContactDetailsImage}
        maxWidth={{ base: 0, md: "100%" }}
        flexBasis="20%"
        h="auto"
    />
);

export const ContactDetails: FC = () => {
    return (
        <Flex w="100%" flexDir="column">
            <Routes>
                <Route path="new" element={<NewUser />} />
            </Routes>
            <HStack alignItems="flex-start">
                <Flex flex={1} justifyContent="space-around" mt="5vh" p={5}>
                    <ContactDetailsSection />
                </Flex>
                <AccentImage />
            </HStack>
        </Flex>
    );
};
