import { Button, HStack, ModalBody, ModalFooter } from "@chakra-ui/react";
import LoadingSpinnerOverlay from "components/LoadingSpinnerOverlay";
import { useInvalidateAccountSummary } from "hooks/useAccountSummary";
import useCreatePayoutRequest from "hooks/useCreatePayoutRequest";
import { useInvalidatePayoutRequests } from "hooks/usePayoutRequests";
import { FC } from "react";

import RequestConfirmationDetails, {
    ConfirmationDetailsProps,
} from "../ConfirmationDetails";
import { usePayoutRequestSummary } from "../ConfirmationDetails/AmountsSummary";

const PayoutConfirmStep2: FC<
    {
        active: boolean;
        onBack: () => void;
        onNext: () => void;
    } & ConfirmationDetailsProps
> = ({ payoutRequest, active, onBack, onNext }) => {
    const { mutateAsync: createPayoutRequest, isLoading } =
        useCreatePayoutRequest();

    const invalidatePayoutRequests = useInvalidatePayoutRequests();
    const invalidateAccountSummary = useInvalidateAccountSummary();
    const handleConfirm = async () => {
        if (!payoutRequest) {
            console.error("No payout request to confirm, shouldn't happen");
            return;
        }
        try {
            await createPayoutRequest(payoutRequest);

            // NOTE: We'll only do these steps if the request was successful
            // To update the balance
            invalidateAccountSummary();

            // Invalidate the payout requests query so that the table updates
            invalidatePayoutRequests();

            // Go to the next step of the modal
            onNext();
        } catch (e) {
            // TODO: Do we handle errors here? Or use the mutation's error state, etc?
            // If I don't do anything in the catch here, I should be able to drop the try/catch altogether
            console.error(e);
        }
    };
    const {
        isBalanceSufficient,
        isPayoutAmountSufficient,
        wireTransferNotEnough,
    } = usePayoutRequestSummary(payoutRequest);

    // If they're an admin, these checks always come back clean
    // This is mostly because we can't fetch the user's balance as it stands
    // TODO: Properly handle getting account summary for the selected user as an admin
    const requestInvalid =
        !isBalanceSufficient ||
        !isPayoutAmountSufficient ||
        wireTransferNotEnough;

    return active ? (
        <>
            <LoadingSpinnerOverlay isLoading={isLoading} />
            <ModalBody>
                <RequestConfirmationDetails payoutRequest={payoutRequest} />
            </ModalBody>
            <ModalFooter as={HStack}>
                <Button variant="danger" onClick={onBack} size="sm">
                    Back
                </Button>
                <Button
                    variant="primary"
                    size="sm"
                    onClick={handleConfirm}
                    disabled={requestInvalid}
                >
                    Submit Request
                </Button>
            </ModalFooter>
        </>
    ) : null;
};

export default PayoutConfirmStep2;
