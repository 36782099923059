import { useMutation, useQuery } from "@tanstack/react-query";
import { Shipment, ShipmentCarrier } from "models/shipping";
import { useAuthenticatedRequest, useAuthenticatedRequestCreator } from "./useRequests";

interface LabelRequest {
    id: number,
    option: NewShipmentSelectOption,
    length?: number;
    width?: number;
    height?: number;
    weight?: number;
}

interface WeightOptions {
    unitName: string;
    unitAbbr: string;
    toOz: (weight?: number) => number | undefined;
    fromOz: (weightInOz: number) => number;
}

export interface NewShipmentSelectOption {
    value: number,
    custom: boolean,
    label: string,
}

export const NewShipmentSelectOptions: NewShipmentSelectOption[] = [
    { value: 0, custom: true, label: 'USPS First Class (Custom)' },
    { value: 1, custom: false, label: 'USPS First Class (4"x7"x1")' },
    { value: 2, custom: false, label: 'USPS Priority - Flat Rate Padded Envelope ' },
    { value: 3, custom: false, label: 'USPS Priority - Small Flat Rate Box' },
    { value: 4, custom: false, label: 'USPS Priority - Medium Flat Rate Box' },
    { value: 5, custom: false, label: 'USPS Priority - Large Flat Rate Box' },
    { value: 6, custom: true, label: 'UPS Ground (Custom)' }
];

const requestPayload = (req: LabelRequest) => {
    const payload = {
        shipmentFriendlyId: req.id,
        newCarrier: ShipmentCarrier.USPS,
        serviceLevel: "usps_priority"
    };

    const { length, width, height, weight } = req;
    if (req.option.custom) {
        if (!length || !width || !height) {
            throw Error('invalid custom dimensions');
        }
    }

    switch (req.option.value) {
        case 0:
            return { ...payload, serviceLevel: "usps_first", length, width, height };
        case 1:
            return { ...payload, serviceLevel: "usps_first", length: 7, width: 4, height: 1 };
        case 2:
            return { ...payload, template: "USPS_FlatRatePaddedEnvelope" };
        case 3:
            return { ...payload, template: "USPS_SmallFlatRateBox" };
        case 4:
            return { ...payload, template: "USPS_MediumFlatRateBox1" };
        case 5:
            return { ...payload, template: "USPS_LargeFlatRateBox" };
        case 6:
            return { ...payload, newCarrier: ShipmentCarrier.UPS, serviceLevel: "ups_ground", length, width, height, weight }
        default:
            throw Error('invalid shipment option');
    }
}

const useWeightOptions = (option: NewShipmentSelectOption | undefined): WeightOptions => {
    if (option?.value === 6) {
        return {
            unitName: "Pounds",
            unitAbbr: "lb",
            toOz: v => v === undefined ? undefined : v * 16,
            fromOz: v => v / 16
        }
    }

    return {
        unitName: "Ounces",
        unitAbbr: "oz",
        toOz: v => v,
        fromOz: v => v
    }
}

const useShipmentByFriendlyId = (id: number | undefined) => {
    const find = useAuthenticatedRequest<Shipment>({
        method: "get",
        url: `/shipping/shipments/${id}`,
    }, [404]);

    return useQuery(["shipment", "label", id], find, { enabled: !!id, retry: false });
}

const useNewShipmentLabel = () => {
    const post = useAuthenticatedRequestCreator(
        (req: LabelRequest) => ({
            method: "post",
            url: "/shipping/labels/new",
            data: requestPayload(req)
        })
    );

    const { mutateAsync: requestNewLabel, isLoading: requesting } = useMutation(post);

    return { requestNewLabel, requesting };
}

export { useNewShipmentLabel, useShipmentByFriendlyId, useWeightOptions };

