import { Box, forwardRef } from "@chakra-ui/react";
import { Form, FormItemProps, Input } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { ReactNode } from "react";

export type SignUpFormItemProps = FormItemProps & {
    title: string;
    password?: boolean;
    textArea?: boolean;
    rows?: number;
    required?: boolean;
    autoComplete?: string;
    labelColor?: string;
};

export const SignUpFormItem = forwardRef(
    (
        {
            title,
            password = false,
            textArea = false,
            rows = 4,
            rules,
            required = true,
            autoComplete,
            labelColor,
            ...props
        }: SignUpFormItemProps,
        ref
    ) => {
        const itemName = title.replace(/\s/g, "").toLowerCase();

        const inputComponent = (
            password ? Input.Password : textArea ? TextArea : Input
        ) as any;

        return (
            <Box
                as={Form.Item}
                ref={ref}
                label={
                    (
                        <span style={{ color: (labelColor ? labelColor : "var(--dcs-black)") }}>
                            {title}
                        </span>
                    ) as ReactNode
                }
                name={itemName}
                {...props}
                mr="1rem"
                mt="6"
                mb="0"
                // For the antd form item, keeps the label always above the input
                // Basicaly just make both of them take up the entire "column", since we have no other control
                wrapperCol={{
                    flex: "1 0",
                }}
                labelCol={{
                    flex: "1 0",
                }}
                rules={[
                    ...(required
                        ? [{ required: true, message: `${title} is required` }]
                        : []),
                    ...(rules || []),
                ]}
                {...props}
            >
                <Box
                    as={inputComponent}
                    placeholder={title}
                    autoComplete={autoComplete}
                    outline="1px solid"
                    px="1rem"
                    py="0.5rem"
                    _placeholder={{
                        fontSize: "1rem",
                        color: "var(--dcs-gray)",
                    }}
                    {...(textArea && { rows })}
                />
            </Box>
        );
    }
);
