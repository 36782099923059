import { Box } from "@chakra-ui/react";
import { ColumnsType } from "antd/lib/table";
import Currency from "components/Currency";
import { useIdentity } from "context/auth";
import {
    PayoutRequest,
    PayoutRequestMethod,
    PayoutRequestStatus,
} from "models/payoutRequests";
import { localizedDate } from "util/helpers";

import PayoutRequestActions from "./PayoutRequestActions";

export const STATUS_LABELS: Record<PayoutRequestStatus, string> = {
    [PayoutRequestStatus.Cancelled]: "Cancelled",
    [PayoutRequestStatus.Pending]: "Pending",
    [PayoutRequestStatus.Complete]: "Completed",
    [PayoutRequestStatus.Unknown]: "Unknown",
    [PayoutRequestStatus.InfoNeeded]: "Info. Needed"
};

export const METHOD_LABELS: Record<PayoutRequestMethod, string> = {
    [PayoutRequestMethod.Paypal]: "Paypal",
    [PayoutRequestMethod.Wire]: "Wire",
    [PayoutRequestMethod.ACH]: "ACH",
    [PayoutRequestMethod.Check]: "Check",
    [PayoutRequestMethod.Other]: "Other",
    [PayoutRequestMethod.Unknown]: "Unknown",
    [PayoutRequestMethod.InternationalWire]: "Intl. Wire"
};

const getColumns = (flat: boolean): ColumnsType<PayoutRequest> =>
    [
        {
            title: "Status",
            dataIndex: "status",
            key: "status",
            render: (status: PayoutRequestStatus) => (
                <Box fontWeight="semibold">{STATUS_LABELS[status]}</Box>
            )
        },
        {
            title: "Date",
            dataIndex: "requestDate",
            key: "requestDate",
            sorter: true,
            render: localizedDate,
        },
        {
            title: "Payout Type",
            dataIndex: "method",
            key: "method",
            render: (method: PayoutRequestMethod) => METHOD_LABELS[method],
        },
        {
            title: "Transfered Out",
            dataIndex: "grossAmountInCents",
            key: "grossAmountInCents",
            sorter: true,
            align: "right",
            render: (a, { status }) =>
                status === PayoutRequestStatus.Cancelled ? (
                    <Currency cents={a} textDecor="line-through" opacity={0.5} flat={flat} />
                ) : (
                    <Currency cents={a} flat={flat} />
                ),
        },
        {
            title: "Incurred Fees",
            dataIndex: "feesInCents",
            key: "feesInCents",
            sorter: true,
            align: "right",
            render: (a, { status }) =>
                status === PayoutRequestStatus.Cancelled ? (
                    <Currency cents={-a} textDecor="line-through" opacity={0.5} flat={flat} />
                ) : (
                    <Currency cents={-a} flat={flat} />
                ),
        },
        {
            title: "Payout Amount",
            dataIndex: "netAmountInCents",
            key: "netAmountInCents",
            sorter: true,
            align: "right",
            render: (a, { status }) =>
                status === PayoutRequestStatus.Cancelled ? (
                    <Currency cents={a} textDecor="line-through" opacity={0.5} flat={flat} />
                ) : (
                    <Currency cents={a} flat={flat} />
                ),
        },
        {
            key: "actions",
            render: (request: PayoutRequest) =>
                (request.status === PayoutRequestStatus.Pending || request.status === PayoutRequestStatus.InfoNeeded) && (
                    <PayoutRequestActions request={request} />
                ),
        }
    ];

const usePayoutColumns = (): ColumnsType<PayoutRequest> => {
    const { isAdmin } = useIdentity();

    const columns = getColumns(isAdmin || false);

    return isAdmin
        ? [
            {
                title: "From",
                dataIndex: "requestingUserFirstName",
                key: "requestingUserFirstName",
                render: (
                    a,
                    { requestingUserFirstName, requestingUserLastName }
                ) => `${requestingUserFirstName} ${requestingUserLastName}`,
            },
            ...columns,
        ]
        : columns;
};

export default usePayoutColumns;
