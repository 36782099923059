import { CodeSandboxOutlined } from "@ant-design/icons";
import { Text } from "@chakra-ui/react";
import { CssVariables } from "models/common";
import { FC } from "react";

const LabelButton: FC<{
    shipmentId: string
}> = ({ shipmentId }) => {
    return <Text as="a"
        pr={5}
        fontSize={30}
        cursor='pointer'
        href={`/api/shipping/shipments/${shipmentId}/label`}
        target='_blank'
        rel='noopener, noreferrer'>
        <CodeSandboxOutlined style={{ color: CssVariables.blue }} />
    </Text>;
}

export default LabelButton;