import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useAuthenticatedRequestCreator, usePublicRequest } from "./useRequests";

export interface PayoutFees {
    achInCents: number;
    wireInCents: number;
    internationalWireInCents: number;
    /**
     * The fee in percentage points Paypal will take. for display purposes only, as
     * we have no control over this fee.
     */
    paypalDisplay: number;
    /**
     * The various fees that apply to shipping a check, based on destination and method
     */
    check: CheckPayoutFees;
}

export interface CheckPayoutFees {
    upsStandardDomesticInCents: number;
    upsOvernightDomesticInCents: number;
    upsStandardInternationalInCents: number;
    upsOvernightInternationalInCents: number;
}

const PayoutFeesPrefix = ["payout-fees"] as const;

const useGetPayoutFees = () => {
    const get = usePublicRequest<PayoutFees>({
        method: "GET",
        url: "/payout-requests/fees",
    });

    return useQuery(PayoutFeesPrefix, get, { suspense: true });
}

const useUpdatePayoutFees = () => {
    const queryClient = useQueryClient();

    const update = useAuthenticatedRequestCreator<void, PayoutFees>(
        newFees => ({
            method: "POST",
            url: "/website-settings/update-payout-fees",
            data: newFees
        })
    )

    const { mutateAsync: updatePayoutFees, isLoading: isUpdating } = useMutation(update, {
        onSuccess: () => {
            queryClient.invalidateQueries(PayoutFeesPrefix)
        }
    });

    return { updatePayoutFees, isUpdating };
};

export { useGetPayoutFees, useUpdatePayoutFees };

