import { Box, useDisclosure, VStack } from "@chakra-ui/react";
import LoadingSpinnerOverlay from "components/LoadingSpinnerOverlay";
import { useIdentity } from "context/auth";
import { useInvalidateAccountSummary } from "hooks/useAccountSummary";
import useCancelPayoutRequest from "hooks/useCancelPayoutRequest";
import { useInvalidatePayoutRequests } from "hooks/usePayoutRequests";
import { PayoutRequest, PayoutRequestStatus } from "models/payoutRequests";
import { FC } from "react";

import CancelConfirmationModal from "./RequestPayoutModal/CancelConfirmationModal";
import PayoutApproval from "./PayoutApproval";
import InfoNeededConfirmationModal from "./RequestPayoutModal/InfoNeededConfirmationModal";
import usePayoutRequestInfoNeeded from "hooks/usePayoutRequestInfoNeeded";

const PayoutRequestActions: FC<{ request: PayoutRequest }> = ({ request }) => {
    const { id } = request;
    // The id is already baked in
    const { cancelPayoutRequest, isLoading: isCancelling } = useCancelPayoutRequest(id);
    const { payoutRequestInfoNeeded, isLoading: isRequestingInfo } = usePayoutRequestInfoNeeded(id);

    const invalidatePayoutRequests = useInvalidatePayoutRequests();
    const invalidateAccountSummary = useInvalidateAccountSummary();

    // Are you sure? cancel modal
    const {
        isOpen: cancelIsOpen,
        onOpen: cancelOnOpen,
        onClose: cancelOnClose
    } = useDisclosure();

    // Are you sure? info needed modal
    const {
        isOpen: infoIsOpen,
        onOpen: infoOnOpen,
        onClose: infoOnClose
    } = useDisclosure();

    const handleCancelClick = () => {
        cancelOnOpen();
    };

    const handleInfoClick = () => {
        infoOnOpen();
    }

    // Handle when the user confirms they want to cancel
    const handleCancel = async (reason: string) => {
        cancelOnClose();
        await cancelPayoutRequest(reason);

        // To update the balance
        invalidateAccountSummary();
        // To update the table
        invalidatePayoutRequests();
    };

    const handleInfoNeeded = async () => {
        infoOnClose();
        await payoutRequestInfoNeeded();
        invalidatePayoutRequests();
    }

    // Admins see the option to approve
    const { isAdmin } = useIdentity();

    return (
        <>
            <LoadingSpinnerOverlay isLoading={isCancelling || isRequestingInfo} />
            <CancelConfirmationModal
                isAdmin={isAdmin}
                request={request}
                isOpen={cancelIsOpen}
                onClose={cancelOnClose}
                onConfirm={handleCancel}
            />
            <InfoNeededConfirmationModal
                request={request}
                isOpen={infoIsOpen}
                onClose={infoOnClose}
                onConfirm={handleInfoNeeded}
            />
            <VStack align="right" spacing={1}>
                {isAdmin && <PayoutApproval request={request} onApproved={invalidatePayoutRequests} />}
                <Box color="blue" as="a" onClick={handleCancelClick}>
                    Cancel
                </Box>
                {isAdmin && request.status !== PayoutRequestStatus.InfoNeeded && <Box color="blue" as="a" onClick={handleInfoClick}>
                    Info. Needed
                </Box>}
            </VStack>
        </>
    );
};

export default PayoutRequestActions;
