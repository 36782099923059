import { FC } from "react";
import MoreInfoContact from "../MoreInfoContact";

import TextInput from "./Inputs/TextInput";

const ACHPayoutDetails: FC = () => (
    <>
        <TextInput
            maxW="15rem"
            name="nameOnAccount"
            label="Name on Account"
            autoComplete="name"
        />
        <TextInput
            maxW="15rem"
            name="accountDigits"
            maxLength={4}
            type="number"
            label="Last 4 Digits of Bank Acct. Number"
        />
        <MoreInfoContact />
    </>
);

export default ACHPayoutDetails;
