import { Box, Flex, HStack } from "@chakra-ui/react";
import { Footer } from "antd/lib/layout/layout";
import BasicLoadingSpinner from "components/BasicLoadingSpinner";
import { Navigate } from "components/Navigate";
import { NavHeader } from "components/Navigation/NavHeader";
import { Roles } from "context/auth";
import { FC, PropsWithChildren, Suspense } from "react";
import { Outlet } from "react-router-dom";
import useRoleRedirection from "./useRoleRedirection";

const TyperLayout: FC<PropsWithChildren> = ({ children }) => {
    const { shouldRedirect, destination } = useRoleRedirection([Roles.Typer]);

    if (shouldRedirect) {
        return <Navigate to={destination!} replace />;
    }

    return (
        <Flex
            flexDir="column"
            minH="100vh"
            w="100%"
            justifyContent="space-between"
        >
            <Box>
                <NavHeader />
                <Suspense fallback={<BasicLoadingSpinner h="50rem" w="100%" />}>
                    <HStack align="start">
                        <Box flex={1} style={{
                            margin: '0 auto',
                            padding: '0 4rem'
                        }}>
                            {children ? children : <Outlet />}
                        </Box>
                    </HStack>
                </Suspense>
            </Box>
            <Footer />
        </Flex>
    );
};

export default TyperLayout;