import { Box, Flex, HStack, VStack } from "@chakra-ui/react";
import { Button, Checkbox, Radio, RadioChangeEvent, Table } from "antd";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import TableActions from "components/TableActions";
import { useIdentity } from "context/auth";
import usePayoutRequests, {
    usePayoutRequestsCSV,
} from "hooks/usePayoutRequests";
import { FilterBy, InitialPageRequest, PageRequest, PageSizeOptions } from "models/common";
import {
    ACHPayoutMethodDetails,
    CheckPayoutMethodDetails,
    InternationalWirePayoutMethodDetails,
    PayoutRequest,
    PayoutRequestMethod,
    PayoutRequestStatus,
    PaypalPayoutMethodDetails,
    WirePayoutMethodDetails,
} from "models/payoutRequests";
import { FC, PropsWithChildren, useState } from "react";
import downloadAsCSV from "util/downloadAsCSV";
import { localizedDate } from "util/helpers";

import { PrinterOutlined } from "@ant-design/icons";
import usePayoutColumns, { STATUS_LABELS } from "./usePayoutColumns";

const DetailContainer: FC<PropsWithChildren & { request: PayoutRequest }> = ({
    request,
    children,
}) => {
    return (
        <Box ml="50px">
            {children}
            {request.approvedDate && (
                <Box>
                    <DetailLabel
                        label="Date sent"
                        value={localizedDate(request.approvedDate)}
                    />
                </Box>
            )}
        </Box>
    );
};

const DetailLabel: FC<{
    label: string;
    value: string;
}> = ({ label, value }) => {
    return (
        <>
            <label>{label}:</label>{" "}
            <Box as="span" fontWeight="bold">
                {value}
            </Box>
        </>
    );
};

const PaymentDetail: FC<{
    request: PayoutRequest;
    isAdmin?: boolean;
}> = ({ request, isAdmin }) => {
    const { method, requestingUserId, requestingUserEmail, details } = request;

    const getCustomerName = (
        details: ACHPayoutMethodDetails | WirePayoutMethodDetails | InternationalWirePayoutMethodDetails
    ) => {
        if (
            details.nameOnAccount !== null &&
            details.nameOnAccount.trim() !== ""
        ) {
            return details.nameOnAccount;
        } else {
            return `${request.requestingUserFirstName || ""} ${request.requestingUserLastName || ""
                }`;
        }
    };

    const customerDetails = isAdmin ? (
        <HStack>
            {requestingUserEmail && (
                <DetailLabel
                    label="Customer Email"
                    value={requestingUserEmail}
                />
            )}
            {requestingUserId && (
                <DetailLabel label="Customer ID" value={requestingUserId} />
            )}
        </HStack>
    ) : (
        <></>
    );

    switch (method) {
        case PayoutRequestMethod.ACH: {
            const achDetails = details as ACHPayoutMethodDetails;
            return (
                <DetailContainer request={request}>
                    <HStack>
                        <DetailLabel
                            label="Name on Account"
                            value={getCustomerName(achDetails)}
                        />
                        <DetailLabel
                            label="Account # Ends With"
                            value={achDetails.accountDigits}
                        />
                    </HStack>
                    {customerDetails}
                </DetailContainer>
            );
        }

        case PayoutRequestMethod.Wire: {
            const wireDetails = details as WirePayoutMethodDetails;
            return (
                <DetailContainer request={request}>
                    <HStack>
                        <DetailLabel
                            label="Name on Account"
                            value={getCustomerName(wireDetails)}
                        />
                        <DetailLabel
                            label="Account # Ends With"
                            value={wireDetails.accountDigits}
                        />
                    </HStack>
                    {customerDetails}
                </DetailContainer>
            );
        }

        case PayoutRequestMethod.InternationalWire: {
            const intlWireDetails = details as WirePayoutMethodDetails;
            return (
                <DetailContainer request={request}>
                    <HStack>
                        <DetailLabel
                            label="Name on Account"
                            value={getCustomerName(intlWireDetails)}
                        />
                        <DetailLabel
                            label="Account # Ends With"
                            value={intlWireDetails.accountDigits}
                        />
                    </HStack>
                    {customerDetails}
                </DetailContainer>
            );
        }

        case PayoutRequestMethod.Check: {
            const checkDetails = details as CheckPayoutMethodDetails;
            return (
                <DetailContainer request={request}>
                    <HStack>
                        <DetailLabel label="Payee" value={checkDetails.payee} />
                        <DetailLabel
                            label="Shipping"
                            value={checkDetails.shippingMethod}
                        />
                        {checkDetails.trackingNumber && <>
                            <label>Tracking Number:</label>
                            <Button
                                style={{ margin: '0 0 0 7px', padding: 0 }}
                                type="link"
                                target="_blank"
                                href={checkDetails.trackingUrl || ''}>
                                {checkDetails.trackingNumber}
                            </Button>
                        </>}
                        {isAdmin && checkDetails.labelUrl && <Button
                            type="link"
                            icon={<PrinterOutlined />}
                            target="_blank"
                            href={checkDetails.labelUrl} />}
                    </HStack>
                    <DetailLabel label="Address" value="" />
                    <Box as="address" ml="20px">
                        {checkDetails.addressLine1}
                        <br />
                        {checkDetails.addressLine2 && (
                            <span>
                                {checkDetails.addressLine2}
                                <br />
                            </span>
                        )}
                        {checkDetails.city} {checkDetails.state},{" "}
                        {checkDetails.zipCode}
                        <br />
                        {checkDetails.country}
                    </Box>
                    {customerDetails}
                </DetailContainer>
            );
        }

        case PayoutRequestMethod.Paypal:
            return (
                <DetailContainer request={request}>
                    <DetailLabel
                        label="Paypal Email"
                        value={(details as PaypalPayoutMethodDetails).email}
                    />
                    {customerDetails}
                </DetailContainer>
            );

        default:
            return <></>;
    }
};

const PayoutsTable: FC<{
    adminStatusFilter?:
    | PayoutRequestStatus.Pending
    | PayoutRequestStatus.Complete,
    showFilter?: boolean,
    defaultExpandRows?: boolean,
}> = ({ adminStatusFilter, showFilter = false, defaultExpandRows = false }) => {
    const columns = usePayoutColumns();

    const [{ page, withinDays, sort, filterBy, pageSize }, setPageRequest] =
        useState<PageRequest>(InitialPageRequest);

    // NOTE: The hook controls which endpoint is hit, including between admin and customer
    const { isLoading, data } = usePayoutRequests(
        {
            page,
            withinDays,
            sort,
            filterBy,
            pageSize
        },
        adminStatusFilter
    );

    const { data: records, totalRecords } = data || {};

    const daysChanged = (value: number) => {
        setPageRequest({ page: 1, withinDays: value, sort, filterBy, pageSize });
    };

    const tableChanged = (
        { current: newPage, pageSize: newPageSize }: any, // pagination
        _: any, // filters, don't care
        { column, field, order }: any
    ) => {
        setPageRequest({
            page: newPageSize !== pageSize ? 1 : newPage,
            withinDays,
            sort: column ? [field, order === "ascend"] : null,
            pageSize: newPageSize,
            filterBy
        });
    };

    const updateFilterBy = (condition: boolean, filter: FilterBy) => {
        let newFilterBy = filterBy;

        if (condition) {
            newFilterBy = { ...filterBy, ...filter };
        } else if (newFilterBy) {
            Object.keys(filter).forEach(key => delete newFilterBy![key]);
        }

        setPageRequest({ page: 1, withinDays, sort, filterBy: newFilterBy, pageSize });
    }

    const handlePendingFilter = (e: CheckboxChangeEvent) => {
        updateFilterBy(e.target.checked, { status: [PayoutRequestStatus.Pending] });
    };

    const handleInfoNeededFilter = (e: CheckboxChangeEvent) => {
        updateFilterBy(e.target.checked, { status: [PayoutRequestStatus.InfoNeeded] });
    };

    const fetchCSV = usePayoutRequestsCSV({
        withinDays,
        sort,
    });

    const { isAdmin } = useIdentity();

    const handleCSVExport = async () => {
        const csvData = await fetchCSV();
        const date = new Date().toISOString().split("T")[0];
        const filename = `payout-requests-${date}.csv`;
        const guidFieldsToKeep = isAdmin ? ["RequestingUserId"] : [];
        downloadAsCSV(csvData, filename, guidFieldsToKeep);
    };

    const filterType = (e: RadioChangeEvent) => {
        updateFilterBy(e.target.value !== -1, { method: [e.target.value] });
    }

    return (
        <VStack w="100%" spacing={7} minHeight="800px">
            <Flex w="100%" justifyContent="space-between" alignItems="end">
                <Box h="100%" fontSize={36} textAlign="center">
                    Payout Requests
                </Box>
                <Flex pb={2} alignItems="end" flexDir="column">
                    <VStack alignItems='end'>
                        <TableActions
                            onDaysChanged={daysChanged}
                            isLoading={isLoading}
                            showFilter={false}
                            onExportCSV={handleCSVExport}
                        >
                            {!isAdmin && (
                                <Checkbox onChange={handlePendingFilter} style={{ color: "var(--dcs-white)" }}>
                                    Filter by Pending
                                </Checkbox>
                            )}
                        </TableActions>
                        {showFilter && <HStack>
                            <Checkbox onChange={handleInfoNeededFilter}>Only Show {STATUS_LABELS[PayoutRequestStatus.InfoNeeded]}</Checkbox>
                            <Radio.Group defaultValue={-1}
                                size='large'
                                buttonStyle='solid'
                                onChange={filterType}>
                                <Radio.Button value={-1}>All</Radio.Button>
                                <Radio.Button value={PayoutRequestMethod.ACH}>ACH</Radio.Button>
                                <Radio.Button value={PayoutRequestMethod.Check}>Check</Radio.Button>
                                <Radio.Button value={PayoutRequestMethod.Paypal}>Paypal</Radio.Button>
                                <Radio.Button value={PayoutRequestMethod.InternationalWire}>Intl. Wire</Radio.Button>
                                <Radio.Button value={PayoutRequestMethod.Wire}>Wire</Radio.Button>
                            </Radio.Group>
                        </HStack>}
                    </VStack>
                </Flex>
            </Flex>
            <Box w="100%">
                {records && <Table
                    columns={columns}
                    dataSource={records}
                    rowKey={(r) => r.id}
                    onChange={tableChanged}
                    pagination={{
                        total: totalRecords,
                        current: page,
                        pageSize,
                        pageSizeOptions: PageSizeOptions,
                        showSizeChanger: true,
                        showQuickJumper: true,
                    }}
                    expandable={{
                        rowExpandable: (r) =>
                            r.method !== PayoutRequestMethod.Unknown &&
                            r.method !== PayoutRequestMethod.Other,
                        expandedRowRender: (r) => (
                            <PaymentDetail request={r} isAdmin={isAdmin} />
                        ),
                        defaultExpandAllRows: defaultExpandRows
                    }}
                    loading={isLoading}
                />}
            </Box>
        </VStack >
    );
};

export default PayoutsTable;
