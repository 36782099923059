import { VStack } from "@chakra-ui/react";
import TransferBar from "components/TransferBar";
import PayoutsTable from "components/customer/Payouts/PayoutsTable";
import { PayoutRequestStatus } from "models/payoutRequests";
import { FC, Suspense } from "react";

const StaffPayoutsPage: FC = () => {
    return (
        <VStack p="5rem">
            <Suspense>
                <TransferBar />
            </Suspense>
            <PayoutsTable adminStatusFilter={PayoutRequestStatus.Pending} showFilter={true} defaultExpandRows={true} />
            <PayoutsTable adminStatusFilter={PayoutRequestStatus.Complete} />
        </VStack>
    );
};

export default StaffPayoutsPage;
