import { Box, HStack, Text, VStack } from "@chakra-ui/react";
import { Alert, Button, Divider, Form, InputNumber, List, Select } from "antd";
import { Rule } from "antd/lib/form";
import Search from "antd/lib/input/Search";
import { StaffRoutes } from "appRoutePaths";
import ShippingAddress from "components/staff/Shipping/ShippingAddress";
import {
    NewShipmentSelectOption,
    NewShipmentSelectOptions,
    useNewShipmentLabel,
    useShipmentByFriendlyId,
    useWeightOptions
} from "hooks/useShipmentLabelReplacement";
import { Shipment } from "models/shipping";
import moment from "moment-timezone";
import { FC, useEffect, useState } from "react";

const DateFormat = 'MM/DD/YYYY LT';

const dimensionRules: Rule[] = [{ required: true, type: 'number', min: 1 }];

interface Dimensions {
    length: number;
    width: number;
    height: number;
    weight?: number;
}

const ShipmentDetails: FC<{
    shipment: Shipment | undefined,
    onLabelRequest: (opt: NewShipmentSelectOption, dims?: Dimensions) => void,
    working: boolean
}> = ({ shipment, onLabelRequest, working }) => {
    const [selectedOption, setSelectedOption] = useState<NewShipmentSelectOption | undefined>();
    const { unitName, unitAbbr, toOz, fromOz } = useWeightOptions(selectedOption);
    const [form] = Form.useForm<Dimensions>();

    useEffect(() => {
        setSelectedOption(undefined);
    }, [shipment]);

    if (!shipment) {
        return null;
    }

    const submit = async () => {
        if (!selectedOption!.custom) {
            onLabelRequest(selectedOption!);
        } else {
            const dims = await form.validateFields();
            onLabelRequest(selectedOption!, {
                ...dims,
                weight: toOz(dims.weight)
            });

            form.resetFields();
        }
    }

    const convertedWeight = fromOz(shipment.options.dimensions.weightInOz);

    return <VStack alignItems='start' width='100%'>
        <HStack alignItems='start'>
            <Box fontSize={20} mr={100}>
                <Text mb={5}>Shipment #{shipment.friendlyId}</Text>
                <Text>{shipment.destination.recipientName}</Text>
                <ShippingAddress address={shipment.destination} />
                <Text>Weight: {convertedWeight}{unitAbbr}</Text>
            </Box>
            {shipment.labels.length > 0 && <List
                header={<div>Label History</div>}
                dataSource={shipment.labels}
                renderItem={label => <List.Item>
                    {moment(label.generated).format(DateFormat)} <Button type="link" href={label.labelUrl} target="_blank">Label PDF</Button>
                </List.Item>}
            />}
        </HStack>
        <Select
            value={selectedOption}
            disabled={working}
            options={NewShipmentSelectOptions}
            size="large"
            style={{ width: '100%' }}
            placeholder="Select New Shipment Method"
            onChange={(_, o) => setSelectedOption(o as NewShipmentSelectOption)}
        />
        {selectedOption?.custom && <Form form={form}
            initialValues={{ weight: convertedWeight }}
            labelCol={{ span: 10 }}
            wrapperCol={{ span: 12 }}
            layout="inline"
            disabled={working}>
            <Form.Item name="length" label="Length" rules={dimensionRules}>
                <InputNumber placeholder="Inches" />
            </Form.Item>
            <Form.Item name="width" label="Width" rules={dimensionRules}>
                <InputNumber placeholder="Inches" />
            </Form.Item>
            <Form.Item name="height" label="Height" rules={dimensionRules}>
                <InputNumber placeholder="Inches" />
            </Form.Item>
            {selectedOption.value !== 0 && <Form.Item name="weight" label="Weight" rules={[{ type: 'number', min: 1 }]}>
                <InputNumber placeholder={unitName} step="0.1" />
            </Form.Item>}
        </Form>}
        <Button
            size="large"
            type="primary"
            disabled={!selectedOption || working}
            onClick={submit}>
            Request New Label
        </Button>
    </VStack>;
}

const ShipmentLabelReplacement = () => {
    const [id, setId] = useState<number | undefined>();
    const [success, setSuccess] = useState<boolean>(false);
    const { isLoading, data, isError, refetch } = useShipmentByFriendlyId(id);
    const { requestNewLabel, requesting } = useNewShipmentLabel();

    const working = (isLoading && !!id) || requesting;

    const search = (term: string) => {
        setSuccess(false);

        var id = Number(term);
        if (id === 0 || isNaN(id)) {
            setId(undefined);
        }

        setId(id);
    }

    const submitRequest = async (opt: NewShipmentSelectOption, dims?: Dimensions) => {
        setSuccess(false);
        await requestNewLabel({ id: id!, option: opt, ...dims });
        await refetch();
        setSuccess(true);
    }

    return <Box p={50} display='flex'>
        <VStack w='100%'>
            <Box w='100%'>
                <HStack justifyContent='space-between'>
                    <VStack alignItems='start'>
                        <Box fontSize={36}>Replace Shipment Label</Box>
                        <Button style={{ padding: 0 }} type="link" href={StaffRoutes.shipmentBatches.url}>&#8592;Back to Shipment Batches</Button>
                    </VStack>
                </HStack>
            </Box>
            <Divider />
            <Box
                minW={375}
                alignItems='center'
                justifyContent='center'
                p={25}
                border='1px solid gainsboro'
                borderRadius={10}
                bgColor='#f0f4fb7f'>
                <Search
                    autoFocus
                    enterButton
                    placeholder="Shipment #"
                    size="large"
                    onSearch={search}
                    type="number"
                    disabled={working}
                    style={{ marginBottom: '10px' }} />
                {isError && <Alert
                    style={{ marginBottom: '5px' }}
                    type="error"
                    message="Not Found"
                    description={`No shipment with an id of ${id} could be found.`}
                    showIcon />}
                {success && <Alert
                    style={{ marginBottom: '5px' }}
                    type="success"
                    message="Label Generated"
                    description={<>
                        <Text>A new label has been created for this shipment, and any previous label has been voided.</Text>
                        <Button type="link" href={data!.labels[0].labelUrl} style={{ padding: 0 }} target="_blank">Open Label PDF</Button>
                    </>}
                    showIcon />}
                <ShipmentDetails key={data?.id} shipment={data} onLabelRequest={submitRequest} working={working} />
            </Box>
        </VStack>
    </Box>;
}

export default ShipmentLabelReplacement;