export const PublicRoutes = {
    rootPath: "/",
    shop: {
        routePath: "shop",
        url: "/shop",
    },
    howProcessWorks: {
        routePath: "how-process-works",
        url: "/how-process-works",
    },
    login: { routePath: "login", url: "/login" },
    signUp: { routePath: "sign-up", url: "/sign-up" },
    forgotPassword: { routePath: "forgot-password", url: "/forgot-password" },
    resetPassword: { routePath: "reset-password", url: "/reset-password" },
    contactDetails: { routePath: "contact-details/*", url: "/contact-details" },
    featuredAuctions: {
        routePath: "featured-auctions",
        url: "/featured-auctions",
    },
    faq: { routePath: "faq", url: "/faq" },
    privacyPolicy: { routePath: "privacy-policy", url: "/privacy-policy" },
    externalSupport: { routePath: "external-support", url: "/external-support" }
};

const CustomerRootPath = "customer";
export const CustomerRoutes = {
    rootPath: CustomerRootPath,
    dashboard: {
        routePath: "dashboard",
        url: `/${CustomerRootPath}/dashboard`,
    },
    packages: { routePath: "packages", url: `/${CustomerRootPath}/packages` },
    cards: { routePath: "cards", url: `/${CustomerRootPath}/cards` },
    transactions: {
        routePath: "transactions",
        url: `/${CustomerRootPath}/transactions`,
    },
    requestPayout: {
        routePath: "request-payout",
        url: `/${CustomerRootPath}/request-payout`,
    },
    support: { routePath: "support", url: `/${CustomerRootPath}/support` },
    submissionForm: { url: '/img/submission-form.jpg' },
    profile: { routePath: "profile", url: `/${CustomerRootPath}/profile` },
    shop: { routePath: "shop", url: `/${CustomerRootPath}/shop` },
    checkout: { routePath: "shop/checkout", url: `/${CustomerRootPath}/shop/checkout` },
    orders: { routePath: "shop/orders", url: `/${CustomerRootPath}/shop/orders` },
    order: {
        routePath: "shop/orders/:orderId",
        url: (orderId: string) => `/${CustomerRootPath}/shop/orders/${orderId}`
    },
    orderConfirmation: {
        routePath: "shop/orders/:orderId/confirm",
        url: (orderId: string) => `/${CustomerRootPath}/shop/orders/${orderId}/confirm`
    }
};

const StaffRootPath = "staff";
export const StaffRoutes = {
    rootPath: StaffRootPath,
    dashboard: { routePath: "dashboard", url: `/${StaffRootPath}/dashboard` },
    packages: { routePath: "packages", url: `/${StaffRootPath}/packages` },
    newPackage: {
        routePath: "packages/new",
        url: `/${StaffRootPath}/packages/new`,
    },
    payouts: { routePath: "payouts", url: `/${StaffRootPath}/payouts` },
    cards: { routePath: "cards", url: `/${StaffRootPath}/cards` },
    users: { routePath: "users", url: `/${StaffRootPath}/users` },
    websiteUpdates: {
        routePath: "website-updates",
        url: `/${StaffRootPath}/website-updates`,
    },
    transactions: {
        routePath: "transactions",
        url: `/${StaffRootPath}/transactions`,
    },
    financialSummary: {
        routePath: "financial-summary",
        url: `/${StaffRootPath}/financial-summary`,
    },
    shipmentBatches: {
        routePath: "shipment-batches",
        url: `/${StaffRootPath}/shipment-batches`
    },
    shipments: {
        routePath: "shipment-batches/:shipmentBatchId",
        url: (shipmentBatchId: string) => `/${StaffRootPath}/shipment-batches/${shipmentBatchId}`
    },
    shipmentBatchCards: {
        routePath: "shipment-batch-cards",
        url: (shipmentBatchId: string) => `/${StaffRootPath}/shipment-batches/${shipmentBatchId}/cards`
    },
    shipmentBatchShipments: {
        routePath: "shipment-batch-shipments",
        url: (shipmentBatchId: string, shipmentId?: string) => shipmentId
            ? `/${StaffRootPath}/shipment-batches/${shipmentBatchId}/shipments/${shipmentId}`
            : `/${StaffRootPath}/shipment-batches/${shipmentBatchId}/shipments`
    },
    deadMessages: {
        routePath: "shipment-batch-dead-messages",
        url: `/${StaffRootPath}/shipment-batches/dead-messages`
    },
    labelReplacement: {
        routePath: "shipment-batch-label-replacement",
        url: `/${StaffRootPath}/shipment-batches/new-shipment-label`
    },
    orders: {
        routePath: "orders",
        url: `/${StaffRootPath}/orders`
    },
    auditLog: {
        routePath: "audit-log",
        url: `/${StaffRootPath}/audit-log`
    },
    packageTypes: {
        routePath: "package-types",
        url: `/${StaffRootPath}/package-types`
    },
    unsoldCardBatches: {
        routePath: "unsold-card-batches",
        url: `/${StaffRootPath}/unsold-card-batches`
    },
    unsoldCards: {
        routePath: "unsold-cards",
        url: (unsoldCardBatchId: string) => `/${StaffRootPath}/unsold-card-batches/${unsoldCardBatchId}/cards`
    }
};

const TyperRootPath = "processor";
export const TyperRoutes = {
    rootPath: TyperRootPath,
    dashboard: {
        routePath: 'dashboard',
        url: `/${TyperRootPath}/dashboard`
    },
    cardTitling: {
        routePath: 'card-titling',
        url: (cardFolderId: string, cardId?: string) => `/${TyperRootPath}/card-titling/${cardFolderId}${cardId ? `/card/${cardId}` : ''}`
    }
};