import Cookies from "js-cookie";
import { useState } from "react";
import { IAuthContext, IAuthToken } from "context/auth";

const CookieKeyAuthType = "Type";
const CookieKeyAccessToken = "Token";
const CookieKeyPreImpersonationToken = "PreImpersonationToken";

export const useAuthCookiePersistence = (): IAuthContext => {
    const [authToken, setAuthToken] = useState(
        Cookies.get(CookieKeyAccessToken)
    );
    const [tokenType, setTokenType] = useState(Cookies.get(CookieKeyAuthType));

    const setToken = (token: IAuthToken) => {
        const { tokenType, accessToken } = token || {};

        if (token === undefined) {
            Cookies.remove(CookieKeyAccessToken);
            Cookies.remove(CookieKeyAuthType);
            Cookies.remove(CookieKeyPreImpersonationToken);
        } else {
            let cookieAttribs = undefined;
            if (token.expiresIn) {
                const date = new Date();
                date.setSeconds(date.getSeconds() + token.expiresIn);
                cookieAttribs = { expires: date };
            }

            Cookies.set(CookieKeyAccessToken, accessToken, cookieAttribs);
            Cookies.set(CookieKeyAuthType, tokenType, cookieAttribs);
        }

        setAuthToken(accessToken);
        setTokenType(tokenType);
    };

    const setPreImpersonationToken = (impersonatedToken?: string) => {
        if (impersonatedToken) {
            Cookies.set(CookieKeyPreImpersonationToken, impersonatedToken);
        } else {
            Cookies.remove(CookieKeyPreImpersonationToken);
        }
    };

    const startImpersonating = (impersonatedToken: IAuthToken) => {
        setPreImpersonationToken(authToken);
        setToken(impersonatedToken);
    };

    const stopImpersonating = () => {
        const token: IAuthToken = {
            accessToken: Cookies.get(CookieKeyPreImpersonationToken)!,
            tokenType: Cookies.get(CookieKeyAuthType)!
        };
        setToken(token);
        setPreImpersonationToken(undefined);
    };

    const isImpersonating = (): boolean => {
        return !!Cookies.get(CookieKeyPreImpersonationToken);
    };

    return {
        authToken,
        tokenType,
        setToken,
        startImpersonating,
        stopImpersonating,
        isImpersonating
    };
};
