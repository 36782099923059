import {
    Box,
    Button,
    chakra,
    Flex,
    Image,
    Stack,
    Text,
    VStack,
} from "@chakra-ui/react";
import { CustomerRoutes } from "appRoutePaths";

import { HowDoesThisWork } from "components/HowProcessWorks/HowDoesThisWork";
import { NumbersToProveIt } from "components/HowProcessWorks/NumbersToProveIt";
import LinkHero from "./LinkHero";

const logo = require("images/logo_white_and_blue_no_tagline.png");
const backgroundImage = require("images/Home.jpg");

const SiteBlurb = chakra(() => (
    <Text
        as="h1"
        lineHeight="58px"
        fontSize={{ base: 46, md: 52, "2xl": 52 }}
        fontWeight="800"
        color="white"
        textTransform="uppercase"
        maxW={{ base: "100%", lg: "648px", "2xl": "648px" }}
    >
        The one stop shop
        <Box display="inline-block">
            for all your
            <Box
                display="inline-block"
                px="0.5rem"
                color="var(--dcs-light-green)"
            >
                card
            </Box>
            <Box display="inline-block" color="var(--dcs-light-green)">
                consignment
            </Box>{" "}
            needs!
        </Box>
    </Text>
));

const ContentHero = () => (
    <VStack align="baseline" mb="1rem">
        <Image src={logo} alt="DC Sports 87" maxW="416px" />
        <SiteBlurb />
        <Button
            alignSelf="start"
            as="a"
            href={CustomerRoutes.submissionForm.url}
            target="_blank"
            rel="noreferrer"
            variant="primary"
            width="fit-content"
            style={{ transformOrigin: 'top left', scale: '150%', backgroundColor: 'var(--dcs-dark-blue)' }}
        >
            Print Submission Form
        </Button>
        <Text pt="40px" />
        <Box w="100%" h="2px" bg="var(--dcs-white)" />
    </VStack>
);

export const HomePageHero = () => (
    <Flex as="section" pos="relative" w="100%" minH="40rem">
        <Image
            pos="absolute"
            src={backgroundImage}
            alt="DC Sports 87"
            objectFit="cover"
            objectPosition="top"
            w="100%"
            h="100%"
        />
        <Stack
            w="100%"
            mt="1rem"
            px={{
                base: "2rem",
                md: "max(10vw, 5rem)",
            }}
            direction="column"
        >
            <Stack
                mt="1rem"
                w="100%"
                maxW={{
                    base: "100%",
                    lg: "100%",
                    "2xl": "max(70%, 2000px)",
                }}
                align="center"
                direction={{
                    base: "column",
                    lg: "row",
                }}
                justifyContent="space-between"
                zIndex={1}
            >
                <ContentHero />
                <LinkHero />
            </Stack>
            <NumbersToProveIt />
            <HowDoesThisWork />
        </Stack>
    </Flex>
);

export default HomePageHero;
