import { CheckCircleFilled } from "@ant-design/icons";
import { Box, HStack, Text, VStack } from "@chakra-ui/react";
import { Alert, Button, Divider, Modal, Spin } from "antd";
import { StaffRoutes } from "appRoutePaths";
import Destination from "components/staff/Shipping/Destination";
import LabelButton from "components/staff/Shipping/LabelButton";
import Method from "components/staff/Shipping/Method";
import PrintButton from "components/staff/Shipping/PrintButton";
import ShipmentCardTable from "components/staff/Shipping/ShipmentCardTable";
import ShipmentSearch from "components/staff/Shipping/ShipmentSearch";
import Summary from "components/staff/Shipping/Summary";
import useShipmentCompletion from "hooks/useShipmentCompletion";
import useShipmentNavigation from "hooks/useShipmentNavigation";
import { CssVariables } from "models/common";
import { ShipmentBatchType, ShipmentTypeNames } from "models/shipping";
import { FC } from "react";
import { useNavigate, useParams } from "react-router-dom";

const ShipmentErrors: FC<{ errors: string[] }> = ({ errors }) => {
    if (errors.length === 0) {
        return null;
    }

    return <Alert
        type="error"
        style={{ width: '100%', whiteSpace: 'pre' }}
        showIcon
        message="This shipment will NOT have a label due to the following error(s):"
        description={errors.join('\r\n')}
    />;
}

const ShipmentBatchShipments = () => {
    const { shipmentBatchId, shipmentId } = useParams();
    const { isLoading, data, refetch } = useShipmentNavigation(shipmentBatchId!, shipmentId);
    const { completeShipment } = useShipmentCompletion();
    const navigate = useNavigate();

    const navigateToShipment = (newShipmentId: string) => {
        const shipmentUrl = StaffRoutes.shipmentBatchShipments.url(shipmentBatchId!, newShipmentId!);
        navigate(shipmentUrl);
    };

    if (isLoading) {
        return <Box h="80vh" display="flex" justifyContent="center" alignItems="center">
            <Spin size="large" tip="Loading..." />
        </Box>;
    }

    const {
        hasPreviousShipment,
        hasNextShipment,
        shipmentBatchName,
        shipmentNumber,
        shipmentCount,
        previousShipment,
        currentShipment,
        nextShipment
    } = data!;

    const {
        shipped,
        pullingIncomplete,
        friendlyId,
        shipmentBatchType,
        shipmentType
    } = currentShipment;

    const cardsMissing = currentShipment.itemsPulled !== currentShipment.itemCount;

    const markComplete = async () => {
        const ok = shipped || await completeShipment(currentShipment.shipmentId);

        if (ok && hasNextShipment) {
            navigateToShipment(nextShipment!.shipmentId);
        } else if (ok && !hasNextShipment) {
            refetch();
            Modal.success({
                title: `${shipmentBatchName} Complete`,
                content: 'You have finished shipping this batch.',
                onOk: () => navigate(StaffRoutes.shipmentBatches.url)
            });
        }
    };

    return <Box p={50} display='flex'>
        <VStack w='100%'>
            <Box w='100%'>
                <HStack justifyContent='space-between'>
                    <VStack alignItems='start'>
                        <Text fontSize={20}>{shipmentBatchName}</Text>
                        <Button style={{ padding: 0 }} type="link" href={StaffRoutes.shipmentBatches.url}>&#8592;Back to Shipment Batches</Button>
                    </VStack>
                    <Text fontSize={20} style={{ marginTop: 0 }}>Shipment #{friendlyId}</Text>
                    <VStack alignItems='end'>
                        <HStack>
                            <ShipmentSearch />
                            <PrintButton />
                            <LabelButton shipmentId={currentShipment.shipmentId} />
                            <Text fontSize={20}>
                                {shipped && <CheckCircleFilled style={{ color: CssVariables.darkGreen, paddingRight: '5px' }} title="Shipped!" />}
                                Order {shipmentNumber} of {shipmentCount}
                            </Text>
                        </HStack>
                        {shipmentBatchType === ShipmentBatchType.ArmedForces && <Text>{ShipmentTypeNames[shipmentType]}</Text>}
                    </VStack>
                </HStack>
            </Box>
            <Divider />
            <ShipmentErrors errors={currentShipment.errors} />
            <Box w='100%'>
                <HStack justifyContent='space-between' fontSize={20}>
                    <Destination shipment={currentShipment} />
                    <Summary shipment={currentShipment} />
                </HStack>
            </Box>
            <Box w='100%'>
                <HStack fontSize={20}>
                    <Method shipment={currentShipment} />
                </HStack>
            </Box>
            <Box w='100%'>
                <ShipmentCardTable shipmentId={currentShipment.shipmentId} type={currentShipment.shipmentType} />
                <ShipmentErrors errors={currentShipment.errors} />
            </Box>
            <Box>
                {pullingIncomplete &&
                    <Text color={CssVariables.errorRed} p={2}>Card pulling has not been completed for this shipment.</Text>
                }
                {!pullingIncomplete && cardsMissing &&
                    <Text color={CssVariables.errorRed} p={2} fontWeight='bold'>One or more cards are missing from this shipment.</Text>
                }
                <HStack justifyContent='space-between'>
                    <Button
                        disabled={!hasPreviousShipment}
                        size="large"
                        style={{ margin: '5px' }}
                        type="default"
                        onClick={() => navigateToShipment(previousShipment!.shipmentId)}>
                        Previous
                    </Button>

                    <Button
                        disabled={!hasNextShipment}
                        size="large"
                        style={{ margin: '5px' }}
                        type="default"
                        onClick={() => navigateToShipment(nextShipment!.shipmentId)}>
                        Next
                    </Button>

                    <Button
                        disabled={pullingIncomplete}
                        size="large"
                        style={{ margin: '5px' }}
                        type="primary"
                        onClick={markComplete}>
                        Complete
                    </Button>
                </HStack>
            </Box>
        </VStack >
    </Box >;
}

export default ShipmentBatchShipments;