import { Flex, HStack, Image, Text, VStack } from "@chakra-ui/react";
import { LoginForm } from "components/Login/LoginForm";
import { OrDivider } from "components/SignUp/OrDivider";
import { useLogin } from "hooks/useLogin";
import { getUserDashboardUrl } from "layouts/useRoleRedirection";
import { CssVariables } from "models/common";
import { FC } from "react";
import { Link, Navigate } from "react-router-dom";

const LoginImage = require("images/login-page.png");

const LoginFormSection: FC = () => (
    <Flex w="100%" justify="space-around" maxW={{ base: "unset", md: "66%" }}>
        <Flex
            flex="1"
            flexDir="column"
            align="center"
            pt="7vh"
            px="2rem"
            maxWidth="30rem"
        >
            <VStack spacing={3} align="baseline" w="100%">
                <VStack spacing={1} align="baseline">
                    <Text fontSize="3xl">Sign In</Text>
                    <HStack spacing="2">
                        <Text>New Customer?</Text>
                        <Text
                            as={Link}
                            to="/sign-up"
                            color={CssVariables.antActiveFilter}
                        >
                            Create An Account
                        </Text>
                    </HStack>
                </VStack>
                <OrDivider>Or Sign in With Email</OrDivider>
                <LoginForm />
            </VStack>
        </Flex>
    </Flex>
);

export const Login: FC = () => {
    // TODO: Refactor this out into a "Route" that renders routes that should
    //         automatically redirect to the dashboard if the user is logged in.
    // And then make this route a child of that route ofc.
    const { isLoggedIn, roles } = useLogin();

    if (isLoggedIn) {
        const dashboardUrl = getUserDashboardUrl(roles);
        return <Navigate to={dashboardUrl} />
    }

    return (
        <Flex w="100%" color="#22272c" flex={1} minH="85vh" pos="relative">
            <LoginFormSection />
            <Image
                pos="absolute"
                right="0"
                top="0"
                bottom="0"
                src={LoginImage}
                maxWidth={{ base: 0, md: "30%" }}
                flexBasis="30%"
                h="100%"
                objectFit="cover"
            />
        </Flex>
    );
};
