import { Box } from "@chakra-ui/react";
import {
    PayoutRequestMethod,
    ValidPaymentMethods,
} from "models/payoutRequests";
import { FC } from "react";

import ACHPayoutDetails from "./ACHPayoutDetails";
import CheckPayoutDetails from "./CheckPayoutDetails";
import InternationalWirePayoutDetails from "./InternationalWirePayoutDetails";
import PaypalPayoutDetails from "./PaypalPayoutDetails";
import WirePayoutDetails from "./WirePayoutDetails";

const PayoutDetailsComponentMap: Record<ValidPaymentMethods, FC> = {
    [PayoutRequestMethod.ACH]: ACHPayoutDetails,
    [PayoutRequestMethod.Check]: CheckPayoutDetails,
    [PayoutRequestMethod.Paypal]: PaypalPayoutDetails,
    [PayoutRequestMethod.Wire]: WirePayoutDetails,
    [PayoutRequestMethod.InternationalWire]: InternationalWirePayoutDetails
};

const PayoutMethodDetails: FC<{ method: ValidPaymentMethods | undefined }> = ({
    method,
}) => {
    return (
        <Box w="100%" minH="10rem">
            <Box
                w="100%"
                // Use the method as a key to get the correct component to render
                as={method && PayoutDetailsComponentMap[method]}
            />
        </Box>
    );
};

export default PayoutMethodDetails;
