import { Box, forwardRef, HStack } from "@chakra-ui/react";

const SummaryDetail = forwardRef<{ label: string }, typeof HStack>(
    ({ label, children, ...props }, ref) => (
        <HStack ref={ref} w="100%" justifyContent="space-between" {...props}>
            <Box flex={1} fontWeight="semibold">
                {label}:
            </Box>
            <Box>{children}</Box>
        </HStack>
    )
);

export default SummaryDetail;
