import { LoadingOutlined } from "@ant-design/icons";
import { Box, HStack, VStack } from "@chakra-ui/react";
import { Alert, Button, Form, Input, Select, Skeleton } from "antd";
import useContactDetails from "hooks/useContactDetails";
import useCountryOptions from "hooks/useCountryOptions";
import useProfile from "hooks/useProfile";
import { UserProfile } from "models/profile";
import { FC, useState } from "react";

const Banner: FC<{ success: boolean, working: boolean }> = ({ success, working }) => {
    if (working) {
        return <Alert type="info" message="Saving..." showIcon icon={<LoadingOutlined />} />;
    }

    if (success) {
        return <Alert type="success" message="Profile updated successfully!" showIcon />;
    }

    return null;
}

const Profile = () => {
    const postContactDetails = useContactDetails();
    const { isLoading, data, refetch } = useProfile();
    const [form] = Form.useForm();
    const [success, setSuccess] = useState<boolean>(false);
    const [working, setWorking] = useState<boolean>(false);
    const countries = useCountryOptions();

    if (isLoading) {
        return <Skeleton />;
    }

    const saveProfile = async (profile: UserProfile) => {
        try {
            setWorking(true);
            await postContactDetails(profile);
            await refetch();
            setSuccess(true);
        } finally {
            setWorking(false);
        }
    };

    return <Box p={5} display='flex'>
        <VStack>
            <Box w='100%'>
                <Banner success={success} working={working} />
            </Box>
            <Form
                layout="vertical"
                form={form}
                initialValues={data}
                disabled={working}
                onFinish={saveProfile}>
                <Form.Item
                    label="Email Address"
                    name="email"
                    rules={[{ required: true }]}
                >
                    <Input size="large" />
                </Form.Item>

                <Form.Item
                    label="Mailing Address Line 1"
                    name="addressLine1"
                    rules={[{ required: true }]}
                >
                    <Input size="large" />
                </Form.Item>

                <Form.Item
                    label="Mailing Address Line 2"
                    name="addressLine2"
                >
                    <Input size="large" />
                </Form.Item>

                <HStack mb="24px">
                    <Form.Item
                        style={{ marginBottom: 0 }}
                        label="City"
                        name="city"
                        rules={[{ required: true }]}
                    >
                        <Input size="large" />
                    </Form.Item>
                    <Form.Item
                        label="State"
                        name="state"
                        rules={[{ required: true }]}
                    >
                        <Input size="large" />
                    </Form.Item>
                    <Form.Item
                        label="Zip Code"
                        name="zipCode"
                        rules={[{ required: true }]}
                    >
                        <Input size="large" />
                    </Form.Item>
                </HStack>
                <Form.Item
                    label="Country"
                    name="country"
                    rules={[{ required: true }]}>
                    <Select size="large" options={countries} />
                </Form.Item>
                <Form.Item style={{ width: '100%', marginTop: 24 }}>
                    <Button size="large" type="primary" htmlType="submit" disabled={working}>
                        Save Changes
                    </Button>
                </Form.Item>
            </Form>
        </VStack>
    </Box >;
}

export default Profile;