import { Box, Flex } from "@chakra-ui/react";
import { Button, Form, Select } from "antd";
import { CustomerRoutes } from "appRoutePaths";
import { SignUpFormItem } from "components/SignUp/SignUpFormItem";
import useContactDetails from "hooks/useContactDetails";
import useCountryOptions from "hooks/useCountryOptions";
import { useProfileState } from "hooks/useProfile";
import { ContactDetails } from "models/profile";
import { FC } from "react";
import { useNavigate } from "react-router";

export const ContactDetailsForm: FC = () => {
    const postContactDetails = useContactDetails();
    const navigate = useNavigate();
    const countries = useCountryOptions();

    const { refetch: refetchProfileState } = useProfileState();

    const saveContactDetails = async (values: any) => {
        // The form value properties are the provided title in lowercase with no spaces
        const {
            city,
            state,
            zipcode,
            phone,
            mailingaddressline1,
            mailingaddressline2,
            email,
            country
        } = values;

        const contactDetailsData: ContactDetails = {
            addressLine1: mailingaddressline1,
            addressLine2: mailingaddressline2,
            city,
            state,
            zipCode: zipcode,
            phoneNumber: phone,
            email,
            country: country
        };

        await postContactDetails(contactDetailsData);
        await refetchProfileState();
        navigate(CustomerRoutes.dashboard.url, { replace: true });
    };

    return (
        <Box
            as={Form}
            layout="vertical"
            onFinish={saveContactDetails}
            w="100%"
            maxWidth="650px"
            initialValues={{ country: countries[0].value }}
        >
            <SignUpFormItem
                title="Email Address"
                name="email"
                autoComplete="email"
                rules={[
                    { type: 'email' }
                ]}
            />

            <SignUpFormItem title="Phone" autoComplete="tel" name="phone" />
            <SignUpFormItem
                title="Mailing Address Line 1"
                autoComplete="address-line1"
            />
            <SignUpFormItem
                title="Mailing Address Line 2"
                required={false}
                autoComplete="address-line2"
            />
            <Flex w="100%" mb='6'>
                <SignUpFormItem title="City" autoComplete="address-level2" />
                <SignUpFormItem
                    title="State"
                    rules={[{ min: 2, validateTrigger: "onBlur" }]}
                    autoComplete="address-level1"
                />
                <SignUpFormItem
                    title="Zip Code"
                    autoComplete="postal-code"
                />
            </Flex>
            <Form.Item
                label="Country"
                name="country"
                rules={[{ required: true }]}>
                <Select
                    options={countries}
                    style={{ outline: '1px solid' }}
                    size="large"
                />
            </Form.Item>
            <Box pt="5">
                <Button type="primary" htmlType="submit">
                    Complete Sign up
                </Button>
            </Box>
        </Box>
    );
};