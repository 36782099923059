import { Box, Flex, HStack } from "@chakra-ui/react";
import { Navigate } from "components/Navigate";
import { Footer } from "components/Navigation/Footer";
import { NavHeader } from "components/Navigation/NavHeader";
import { SideMenu } from "components/Navigation/SideMenu/SideMenu";
import { StaffMenuItems } from "components/Navigation/SideMenu/sideMenuEntries";
import { Roles } from "context/auth";
import { FC, PropsWithChildren } from "react";
import { Outlet } from "react-router-dom";
import useRoleRedirection from "./useRoleRedirection";

const StaffLayout: FC<PropsWithChildren & { showSideMenu: boolean }> = ({ children, showSideMenu }) => {
    const { shouldRedirect, destination, roles } = useRoleRedirection([Roles.Staff, Roles.Admin]);

    if (shouldRedirect) {
        return <Navigate to={destination!} replace />;
    }

    return (
        <Flex
            flexDir="column"
            minH="100vh"
            w="100%"
            justifyContent="space-between"
        >
            <Box>
                <NavHeader />
                <HStack align="start">
                    {showSideMenu && <SideMenu entries={StaffMenuItems(roles)} />}
                    <Box flex={1}>{children ? children : <Outlet />}</Box>
                </HStack>
            </Box>
            <Footer />
        </Flex>
    );
};

export default StaffLayout;
