import { CustomerRoutes, PublicRoutes, StaffRoutes, TyperRoutes } from "appRoutePaths";
import { Roles, useIdentity } from "context/auth";

interface RoleRedirection {
    shouldRedirect: boolean;
    destination?: string;
    roles: Roles[];
}

export const getUserDashboardUrl = (roles: Roles[]) => {
    if (
        roles.includes(Roles.Admin) ||
        roles.includes(Roles.Staff)
    ) {
        return StaffRoutes.dashboard.url;
    } else if (
        roles.includes(Roles.Typer)
    ) {
        return TyperRoutes.dashboard.url;
    } else if (
        roles.includes(Roles.User) ||
        roles.includes(Roles.UserPartner)
    ) {
        return CustomerRoutes.dashboard.url;
    }

    return PublicRoutes.rootPath;
}

const useRoleRedirection = (expectedRoles: Roles[]): RoleRedirection => {
    const { isLoggedIn, roles } = useIdentity();

    const redirectTo = (destination: string) => ({
        shouldRedirect: true,
        destination,
        roles
    });

    const hasExpectedRole = expectedRoles.some(r => roles.includes(r));

    if (!isLoggedIn) {
        return redirectTo(PublicRoutes.login.url);
    } else if (hasExpectedRole) {
        return { shouldRedirect: false, roles };
    }

    const redirectUrl = getUserDashboardUrl(roles);
    return redirectTo(redirectUrl);
};

export default useRoleRedirection;